export const Story = (props) => {
    return(
        <div id='Story'>
            <div className='intro'>
                <div className='overlay'>
                    <div className= "container">
                    <div className="text-center section-title">
                        <h2>We hear you</h2>
                    </div>
                    <div className = "row">
                    <div className = "col-md-4">
                        <p>{props.data? props.data.epic1 : 'Loading'}</p>  
                    </div>
                    <div className = "col-md-4">
                    <p>{props.data? props.data.epic2 : 'Loading'}</p>
                    </div>
                    <div className = "col-md-4">
                    <p>{props.data? props.data.epic3 : 'Loading'}</p>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}