export const Products = (props) => {
    return (
      <div id="product" className='text-center'>
        <div className='container'>
          <div className='col-md-10 col-md-offset-1 section-title'>
            <h2>Product</h2>
          </div>
          <div className="col-xs-12 col-md-12">
              {" "}
              <img src="img/product.png" className="img" alt="" />{" "}
            </div>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="product-text">
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <h3>Features</h3>
                <div className="list-style">
                  <div className="col-md-6 col-sm-12 text-center">
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12 text-center">
                    <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  